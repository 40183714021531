<template>
  <div class="setting">
    <Module-Header title="Settings"></Module-Header>

    <div class="ssl">
      <div class="tip-title"> Suggested Search Labels </div>
      <div class="card">
        <div class="single">
          <div class="label">Area/District</div>
          <div class="list">
            <div class="item" v-for="(its, idx) in settingInfo.suggestedSearchLabels.district" :key="idx">
              {{ its.zhHant }}
              <!-- <img src="@/assets/images/item-close.png" alt="" @click="del('district', idx)"> -->
            </div>
            <div class="add-ipt" v-if="sslType == 'district'">
              <input type="text" v-model="tempStroage" @blur="inputBlur('district')" ref="district" name="" id="">
            </div>
            <!-- <div class="add-item" @click="add('district')">
              <img src="@/assets/images/item-add.png" alt="">
              Add
            </div> -->
          </div>
        </div>

        <div class="single">
          <div class="label">Catering Type</div>
          <div class="list">
            <div class="item" v-for="(its, idx) in settingInfo.suggestedSearchLabels.cateringType" :key="idx">
              {{ its.zhHant }}
              <!-- <img src="@/assets/images/item-close.png" alt="" @click="del('cateringType', idx)"> -->
            </div>
            <div class="add-ipt" v-if="sslType == 'cateringType'">
              <input type="text" v-model="tempStroage" @blur="inputBlur('cateringType')" ref="cateringType" name="" id="">
            </div>
            <!-- <div class="add-item" @click="add('cateringType')">
              <img src="@/assets/images/item-add.png" alt="">
              Add
            </div> -->
          </div>
        </div>
        
        <div class="single">
          <div class="label">咪嘥盒(BYOC)</div>
          <div class="list">
            <div class="item" v-for="(its, idx) in settingInfo.suggestedSearchLabels.byoc" :key="idx">
              {{ its.zhHant }}
              <!-- <img src="@/assets/images/item-close.png" alt="" @click="del('byoc', idx)"> -->
            </div>
            <div class="add-ipt" v-if="sslType == 'byoc'">
              <input type="text" v-model="tempStroage" @blur="inputBlur('byoc')" ref="byoc" name="" id="">
            </div>
            <!-- <div class="add-item" @click="add('byoc')">
              <img src="@/assets/images/item-add.png" alt="">
              Add
            </div> -->
          </div>
        </div>

      </div>
    </div>

    <div class="gs">
      <div class="tip-title">G$ Settings</div>
      <div class="card">
        <div class="single">
          <div class="description">Limit of G$250 coupon exchange per Member per day:</div>
          <div class="handler-data">
            <img src="@/assets/images/G-reduce.png" alt="" @click="minus('greenDollar', 'exchangeGreenDollarCouponLimit')">
            <el-input type="text" v-model="settingInfo.greenDollar.exchangeGreenDollarCouponLimit" class="ipt" @input="str => changeInput(str, 'greenDollar', 'exchangeGreenDollarCouponLimit')"></el-input>
            <img src="@/assets/images/G-add.png" alt="" @click="plus('greenDollar', 'exchangeGreenDollarCouponLimit')">
          </div>
        </div>

        <div class="single">
          <div class="description">Limit of Cutlery coupon exchange per Member per day:</div>
          <div class="handler-data">
            <img src="@/assets/images/G-reduce.png" alt="" @click="minus('greenDollar', 'exchangedCutleryCouponLimit')">
            <el-input type="text" v-model="settingInfo.greenDollar.exchangedCutleryCouponLimit" class="ipt" @input="str => changeInput(str, 'greenDollar', 'exchangedCutleryCouponLimit')"></el-input>
            <img src="@/assets/images/G-add.png" alt="" @click="plus('greenDollar', 'exchangedCutleryCouponLimit')">
          </div>
        </div>
      </div>
    </div>

    <div class="ccs">
      <div class="tip-title">Cutlery Coupon Stock</div>
      <div class="tip-scription">
        <span class="label">Total number of Cutlery Coupon redeemed: </span>
        <span class="value">{{ redeemed }}</span>
      </div>
      <div class="card">
        <div class="single">
          <div class="description">Current stock count of Cutlery Coupon available for exchange:</div>
          <div class="handler-data">{{ settingInfo.cutleryCouponStock.currentStockCount }}</div>
        </div>
        <div class="single">
          <div class="description">Adjust current stock count:</div>
          <div class="handler-data">
            <el-input type="text" v-model.number='currentCount' class="ipt"></el-input>
          </div>
          <div class="remove-count" v-if="diffCount < 0">{{ diffCount }}</div>
          <div class="add-count" v-if="diffCount > 0">+{{ diffCount }}</div>
        </div>
        <div class="tip-scription">
          <span class="label">Current stock count edit history</span>
        </div>
        <el-table :data="settingInfo.cutleryCouponStock.editHistories" ref="table" style="width:100%;">
          <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :min-width="its.width" >
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="bottom" v-if="compare">
      <div class="btn">
        <img src="@/assets/images/btn_icon.png" alt="">
        Saved
      </div>
      <!-- <span>2023/11/11 08:00:00</span> -->
    </div>
    <div class="btn" v-else @click="save"> Save </div>
  </div>
</template>

<script>
import { getSetting, editSetting } from '@/api/setting'
import { toSnakeCase } from '@/utils/data-format'
import { getGreenDollarPointTransactions } from '../../api/GTransactions';

export default {
  data() {
    return {
      tempStroage: '',
      // district, cateringType, byoc
      sslType: '',

      settingInfo: {
        "suggestedSearchLabels": {
          "district": [],
          "cateringType": [],
          "byoc": []
        },
        "greenDollar": {
          "exchangeGreenDollarCouponLimit": 0,
          "exchangedCutleryCouponLimit": 0
        },
        "cutleryCouponStock": {
          currentStockCount: 0,
          totalNumber: 0,
          editHistories: []
        }
      },
      redeemed: 0,
      settingInfoCopy: {},
      currentCount: 0,
      currentCountCopy: 0,

      tableCate: [
        {label: 'Date', prop: 'date'},
        {label: 'Original Stock', prop: 'originalStock'},
        {label: 'Adjusted Stock', prop: 'adjustedStock'},
        {label: 'Change', prop: 'change'},
      ],
      tableData: [],
    }
  },
  computed: {
    compare: function() {
      let {settingInfo, settingInfoCopy, currentCount, currentCountCopy} = this
      return JSON.stringify(settingInfo) === JSON.stringify(settingInfoCopy) 
              && currentCountCopy == currentCount
    },
    diffCount: function() {
      let {currentCount, settingInfo} = this
      return currentCount - settingInfo.cutleryCouponStock.currentStockCount
    }
  },
  methods: { 
    minus(item, type) {
      let greenDollar = this.settingInfo[item]
      greenDollar[type]--
    },
    plus(item, type) {
      let greenDollar = this.settingInfo[item]
      greenDollar[type]++
    },
    changeInput(cte, item, type) {
      this.settingInfo[item][type] = cte.replace(/\D/g, '')
    },

    del(type, idx) {
      let suggestedSearchLabels = this.settingInfo.suggestedSearchLabels
      suggestedSearchLabels[type].splice(idx, 1)
    },
    add(type) {
      this.sslType = type
      this.$nextTick(() => {
        let input = this.$refs[type]
        input.focus()
      })
    },
    inputBlur(type) {
      this.sslType = ''
      let suggestedSearchLabels = this.settingInfo.suggestedSearchLabels
      this.tempStroage.trim() != '' && suggestedSearchLabels[type].push(this.tempStroage)
      this.tempStroage = ''
    },
    
    save() {
      this.$popUp.config({
        content: 'Please confirm whether to save.',
        confirm: {
          e: () => {
            let form = JSON.parse(JSON.stringify(this.settingInfo))
            form.cutlery_coupon_stock = {adjust_stock: this.currentCount}
            form = toSnakeCase(form)

            editSetting(form).then(() => {
              this.$message.success('Successfully Saved')
              // this.settingInfoCopy = JSON.parse(JSON.stringify(form))
              // this.currentCountCopy = this.currentCount
              this.$popUp.removePopup()
              this.$router.go(0)
            })
          }
        },
      })
    },
    getGTran() {
      let form = {
        like_member_id_or_eatery_name: '',
        coupon_type: '',
        status: '',
        start_date: '',
        end_date: '',
        _start: 0,
        _limit: 99999,
      }
      
      getGreenDollarPointTransactions(form).then(v => {
        console.log(v)
        this.redeemed = v.headers['x-total-cutlery-redeemed-count']
      })
    },
    getData() {
      getSetting().then((v) => {
        console.log(v)

        v.data.cutleryCouponStock.editHistories.filter(its => {
          its.date = new Date(its.datetime).format('yyyy/MM/dd hh:mm:ss')
          its.change = its.adjustedStock - its.originalStock 
        })
        this.currentCount = v.data.cutleryCouponStock.currentStockCount
        this.currentCountCopy = this.currentCount
        this.settingInfo = v.data
        this.settingInfoCopy = JSON.parse(JSON.stringify(v.data))
      })
    }
  },
  mounted() {
    this.getData()
    this.getGTran()
  }
}
</script>

<style scoped lang="scss">
.setting {

  .ssl {
    margin-top: 2.5rem;
    color: #1B7379;
    font-weight: 700;

    .tip-title {
      margin-bottom: .9375rem;
      font-size: 1.5rem;
    }

    .card {
      width: 100%;
      padding: 1.25rem;
      box-sizing: border-box;
      border: 1px solid #1B7379;
      border-radius: 1.25rem;
      .label {
        color: #000;
        font-size: 1.125rem;
        font-weight: 700;
        width: 100%;
      }

      .list {
        width: 100%;
        margin: .625rem 0 1.875rem 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;

        .add-ipt {
          width: 200px;
          height: 45px;
          border: 1px solid #1B7379;
          border-radius: 45px;
          box-sizing: border-box;
          padding: 0 20px;
          margin: .625rem .625rem 0 0;

          input {
            outline: none;
            border: none;
            width: 100%;
            height: 100%;
            font-size: 1.125rem;
            color: #000;
          }
        }
        .add-item, .item {
          margin: .625rem .625rem 0 0;
          padding: .625rem 1.5625rem;
          border: 1px solid #1B7379;
          border-radius: 10.25rem;
          color: #1e1e1e;
          font-size: 18px;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: auto;
            margin-left: .3125rem;
            cursor: pointer;
          }
        }

        .add-item {
          background-color: #1B7379;
          color: #fff;
          cursor: pointer;

          img {
            margin: 0 .3125rem 0 0 ;
          }
        }
      }
    }
  }

  .gs {
    margin-top: 2.5rem;
    color: #1B7379;
    font-weight: 700;

    .tip-title {
      margin-bottom: .9375rem;
      font-size: 1.5rem;
    }

    .card {
      width: 100%;
      padding: 1.25rem 1.25rem 0 1.25rem;
      box-sizing: border-box;
      border: 1px solid #1B7379;
      border-radius: 1.25rem;

      .single {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .description {
          color: #000;
          font-size: 1.125rem;
          font-weight: 700;
          width: 36.25rem;
        }

        .handler-data {
          display: flex;
          align-items: center;

          img {
            width: 2.125rem;
            height: auto;
            cursor: pointer;
          }

          .ipt {
            width: 6.25rem;
            margin: 0 .625rem;
          }
        }
      }
    }
  }

  .ccs {
    margin-top: 2.5rem;
    font-weight: 700;
    color: #1B7379;

    .tip-title {
      margin-bottom: .9375rem;
      font-size: 1.5rem;
    }

    .tip-scription {
      width: 100%;
      color: #000;
      font-size: 1.125rem;
      margin-bottom: .9375rem;

      .value {
        font-weight: 400;
        margin-left: .625rem;
      }
    }

    .card {
      width: 100%;
      padding: 1.25rem 1.25rem 0 1.25rem;
      box-sizing: border-box;
      border: 1px solid #1B7379;
      border-radius: 1.25rem;

      .single {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .description {
          color: #000;
          font-size: 1.125rem;
          font-weight: 700;
          width: 36.25rem;
        }

        .handler-data {
          display: flex;
          align-items: center;
          font-weight: 400;
          color: #000;

          .ipt {
            width: 6.25rem;
            margin: 0 .625rem;
          }
        }

        .remove-count {
          color: #C82C2C;
          font-weight: 400;
          font-size: 1.125rem;
        }

        .add-count {
          color: #5ba871;
          font-weight: 400;
          font-size: 1.125rem;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    
    span {
      margin-top: 2.5rem;
      color: #1B7379;
      font-size: .875rem;
      margin-left: 1.25rem;
    }
  }
  .btn {
    width: 17.5rem;
    height: 3.75rem;
    border-radius: 3.75rem;
    background-color: #1B7379;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    cursor: pointer;
    margin-top: 2.5rem;

    img {
      width: 1.75rem;
      height: auto;
      margin-right: 10px;
    }
  }

}
</style>

<style lang="scss">
.ipt {
  & > .el-input__inner {
    text-align: center ;
  }
}
</style>